.navbar{
    background: transparent;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    z-index:20;
    font-family: var(--nunitoFamily);
    position: absolute!important;
    top: 0px!important;
    width: 100%;
}
.navbar.active {
    background: #fff;
    position: -webkit-sticky!important;
    position: fixed!important;
    top: 0;
    width: 100%;
    height: 120px;
    box-shadow: 0px 15px 25px -15px var(--purpleColor);
}
.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    height:100px;
    max-width:1500px;
}
.logo{
    align-items: center;
}
.logo img{
    width: 120%;
    height:80px; 
}

.nav-menu{
    display:flex;
    list-style: none;
    text-align: center;
    padding-top: 10px;
}
.nav-links{
    color: var(--purpleColor);
    text-decoration: none;
    padding: 0.5rem 0.5rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-size: 17px!important;
    font-family: var(--nunitoFamily);
}
.nav-links:hover{
    color: var(--primaryColor)
}
.nav-item{
    line-height: 40px;
    margin-right: 1rem;
}

.nav-item:after{
    content: "";
    display: block;
    height:3px; 
    width: 0;
    background: transparent;
    transition: width .7s ease, background-color 0.5s ease;
}
.nav-item:hover:after{
    width:100%;
    background: var(--primaryColor);
}
.nav-item .active{
    color:var(--primaryColor)
}
.nav-email{
    padding-bottom: 5px;
}
.nav-email a{
    color: var(--primaryColor);
    font-size:25px;
    transition:0.4s all ease;
}
.nav-email a:hover{
    color:var(--purpleColor)
}
.nav-icon{
    display: none;
}
@media screen and (max-width: 1000px){
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top:110px;
        left:-110%;
        opacity:1;  
        transition: all 0.5s  ease;  
    }
    .nav-menu.active{
        background: var(--purpleColor);
        left:0px;
        opacity:1;
        z-index: 1000;
        transition: all 0.5s ease;
        height:100vh;
    }
    .nav-icon{
        display: block;
        cursor: pointer;
        font-size: 25px;
        color: var(--primaryColor);
        position: relative;
        margin-top: -20px;
    }
    .nav-links{
        padding:0.5rem;
        width: 100%;
        display: table;
        text-align:left;
        color:#fff;
    }
    .nav-item:after{
        content: "";
        display: block;
        height:0px; 
        width: 0;
        background: transparent;
        transition: width .7s ease, background-color 0.5s ease;
    }
    .nav-item:hover:after{
        width:100%;
        background: transparent;
    }
    .nav-email{
        padding-bottom: 25px;
    }
}

/* css for dropdown palava start here below */
.dropdown-list{
    display: block;
   
}
.dropdown{
    position: absolute!important;
    top:70px!important;
    list-style: none;
    text-align: left;
    padding:0;
    margin:0;
    display: none;
    z-index:20;
    box-shadow: 0px 15px 25px -15px var(--purpleColor);
}
.dropdown-list:hover > .dropdown{
   display: block;
}
.navitem-drop{
    background: #fff;
    padding: 3px 10px;
    min-width:200px;   
}
.navitem-drop:first-child{
    padding-top: 40px;
}
.nav-drop{
    color: var(--purpleColor);
    text-decoration: none;
    font-weight:600;
}
.nav-drop:hover{
    color: var(--primaryColor)
}
.navitem-drop .active{
    color:var(--primaryColor)
}

@media screen and (max-width: 1000px){
    /* .dropdown-list:hover > .dropdown{
        display: none;
     } */
    .dropdown{
        position: absolute!important;
        top:70px!important;
        list-style: none;
        text-align: left;
        padding:0;
        margin-top:40px;
        display: block;
        z-index:20;
        box-shadow: 0px 15px 25px -15px var(--purpleColor);
    }
    .dropdown.height li{
       display: none;
    }
    .navitem-drop{
        background: #fff;
        padding: 3px 10px;
        min-width:200px; 
        margin-left:100px;
    }
    .navitem-drop:first-child{
        padding-top: 0px;
    }
    .fa-caret-down{
        padding-left: 100px;
    }
}


@media screen and (max-width:800px){
    .nav-container{
        width:100%;
        display: flex;
        justify-content: space-between;
    }
    .logo{
        align-items: left;
        margin-left:0px;  
        width: 28%;

    }

    @media screen and (max-width: 780px) {
        .logo img{
            width: 80%;
            height:100px; 
        }
    }
}
@media screen and (max-width: 430px) {
    .logo img{
        width: 100%;
        height:90px; 
    }
}

@media screen and (max-width: 1200px) and (min-width: 1000px){
    .nav-links{
        color: var(--purpleColor);
        text-decoration: none;
        padding: 0.5rem 0.3rem;
        height: 100%;
        border-bottom: 3px solid transparent;
        font-weight:600;
        font-size: 1.0rem;
    }
}