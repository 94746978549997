.aboutFaqSession{
    background: var(--lightYellow);
    width: 100%;
    padding: 50px 0px;
    .text{
        font-family: var(--nunitoFamily);
        color: var(--lightBlack);
        font-size: 16px;
        padding-bottom: 30px;
    }
}

.faqs{
    width: 100%;
}
.faqs .faq{
    margin: 5px 0px 10px 0px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border-top: 2px solid #fff;
    background: #fff;
}
.faqs .faq-question {
    position: relative;
    font-size: 16px;
    padding-right: 80px;
    transition: all 0.4s ease;
    font-family: var(--nunitoFamily);
    color: var(--lightBlack);
}

.faqs  .faq-question::after{
    content:'';
    position: absolute;
    top: 50%;
    right: 0px;
    transform:translateY(-50%);
    width: 30px;
    height: 30px;  
    background-image: url("../../../image/arrow2.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
    
}
.faqs  .faq-answer{
    opacity:0;
    max-height: 0;
    overflow-y: hidden;
    transition:  all 0.4s ease;
    color: var(--lightBlack);
    font-size: 16px; 
    font-family: var(--nunitoFamily);
    font-weight: 500;
    text-align: justify;
    padding: 0px 10px 5px 10px;
     
}
.faqs  .open .faq-question{
    margin-bottom: 25px;
}
.faqs  .open .faq-question::after{
    transform: translateY(-50%) rotate(90deg);
}
.faqs  .open .faq-answer{
    max-height: 1000px;
    opacity: 1;
}

@media screen and (max-width:800px){
    .faqs .faq-question {
        position: relative;
        font-size: 16px;
        font-weight: 600;
        padding-right: 30px;
    }
}