.newsLetterContainer{
    margin-bottom: 40px;
    .newsLetterCard{
        border: 1px solid var(--primaryColor);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        width: 50%;
        margin: 0 auto;
        padding: 30px;
        .imageIcon{
            padding-bottom: 8px;
            .icon{
                font-size: 40px;
                color: var(--primaryColor)
            }
        }
        .form{
            width: 100%;
            .d-flex{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-family: var(--textFamily);
                padding-top: 20px;
                padding-bottom: 5px;
            }
            label{
                font-family: var(--textFamily);
                font-size: 14px;
                font-weight: 600;
            }
            p{
               margin-bottom: 0; 
               color: crimson;
               font-size: 13px;
            }
            input{
                width: 100%;
                border: 1px solid var(--primaryColor);
                padding: 5px;
                outline: none;
                border-radius: 8px;
            }
            .danger{
                background-color: rgba(255, 0, 0, 0.171);
                border: 1px solid crimson
            }
            .submitButton{
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .newsLetterCard{
            width: 80%;
        }
    }
    @media screen and (max-width: 450px) {
        .newsLetterCard{
            width: 90%;
        }
    }
}

.newsLetterMessage{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(36, 37, 39, 0.884);
   
    z-index: 999;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0px;
    .message{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 8px;
        padding: 20px 40px;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media screen and (min-width: 768px) and (max-width: 950px) {
        .message{
            width: 50%;
        }
    }
    @media screen and (min-width: 450px) and (max-width: 768px) {
        .message{
            width: 70%;
        }
    }
    @media screen and (max-width: 450px) {
        .message{
            width: 90%;
        }
    }
    .err-msg{
        p{
            font-size: 15px;
            font-family: var(--textFamily);
            color: crimson
        }
    }
    .success-msg{
        .icon{
            font-size: 25px;
            color: var(--primaryColor);
        }
        h3{
            padding: 10px 0px;
        }
        p{
            font-size: 15px;
            font-family: var(--textFamily);
            color: var(--blackColor);
            padding-bottom: 20px;
        }
    }
}