.faq-page{
    padding: 30px 0px;
}
.servicefaqs{
    width: 100%;
}
.servicefaqs .faq{
    margin: 5px 0px 5px 0px;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    border-top: 2px solid #fff
}
.servicefaqs .faq-question {
    position: relative;
    font-size: 18px;
    font-weight: 600;
    padding-right: 80px;
    transition: all 0.4s ease;
    font-family: var(--textFamily);
    color: #fff;
}

.servicefaqs .faq-question::after{
    content:'+';
    position: absolute;
    top: 50%;
    right: 0px;
    transform:translateY(-50%);
    width: 30px;
    height: 30px;  
    /* background-image: url("../../../image/arrow.JPG"); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
    
}
.servicefaqs .faq-answer{
    opacity:0;
    max-height: 0;
    overflow-y: hidden;
    transition:  all 0.4s ease;
    color: #fff;
    font-size: 16px; 
    font-family: var(--textFamily);
    font-weight: 500;
    text-align: justify;
    padding: 0px 10px 5px 30px;
     
}
.servicefaqs  .open .faq-question{
    margin-bottom: 25px;
}
.servicefaqs  .open .faq-question::after{
    transform: translateY(-50%) rotate(90deg);
}
.servicefaqs  .open .faq-answer{
    max-height: 1000px;
    opacity: 1;
}