.JobSearchContainer{
    width: 100%;
    position: relative;
    padding-top: 50px;
    
    .SearchInputContainer{
        position: relative;
        padding-bottom: 30px;
        .title{
            font-weight: 600;
            font-family: var(--textFamily);
            font-size: 18px;
            padding-bottom: 10px;

        }
        .formInput{
            display: flex;
            width: 100%;
            input{
                flex: 10;
                height: 40px;
                border: 1px solid var(--purpleColor);
                outline: none;
                padding: 5px 10px;
                font-family: var(--textFamily);
                font-size: 15px;
            }
            .button{
                flex: 2;
                background: var(--purpleColor);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
                .icon{
                    color:#fff;
                    font-size: 20px;
                }
            }
        }
    }
}

.jobCardContainer{
    position: relative;
    width: 100%;
    background: var(--lightYellow);
    margin-bottom: 30px;
    .jobTitle{
        background: var(--primaryColor);
        width: 100%;
        line-height: 35px;
        padding: 0px 10px;
        font-family: var(--textFamily);
        font-size: 15px;
    }
    .jobContent{
        padding: 10px 10px;
        display: flex;
        .left{
            flex: 9;
            display: flex;
            align-items: flex-start;
            gap: 20px;
        }
        .right{
            flex: 3;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .image{
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 90%;
            }
        }
        .jobType{
            font-family: var(--textFamily);
            color: var(--blackColor);
            padding-bottom: 10px;
            font-size: 15px;
        }
        .jobLocation{
            .icon{
                color: var(--purpleColor);
                font-size: 16px;
            }
            font-size: 15px;
            font-family: var(--textFamily);
            color: var(--blackColor)
        }
        .right{
            .pageButton2{
                background: transparent;
                border: 1px solid var(--purpleColor);
                color: var(--purpleColor)
            }
        }
    }
}

.jobSearchfooterContainer, 
.pagination-container{
    margin: 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    .paginationButton{
        width: 40px; height: 40px ;
        border-radius: 5px;
        background: var(--purpleColor);
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            font-size: 25px;
            color:#fff;
        }
    }
    .paginationText{
        font-family: var(--textFamily);
        color: var(--blackColor);
        font-size: 16px;
    }

    // react paginate css
    
}
.pagination-btns {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 20px;
}
.pagination-btns .previous-btn,
.pagination-btns .next-btn{
    font-size: 20px;
    color:#fff;
}

.pagination-btns a {
    width: 40px;
    height: 40px;
    padding: 7px;
    border-radius: 5px;
    background: var(--purpleColor);
    text-decoration: none;
    color: #fff!important;
    font-family: var(--textFamily);
    cursor: pointer;
    transition: 0.4s all ease;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paginationBttns a:hover {
    background: var(--primaryColor);
    color: #fff;
}

.paginationActive a {
    background: var(--primaryColor);
    color: #fff;
}