.jobDetailContainer{
    position: relative;
    width: 100%;
    margin-top: 60px;
    background: var(--lightYellow);
    padding: 30px;
    .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: var(--textFamily);
        .image{
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background: #fff;
            img{
                width: 90%;
            }
        }
        .jobTitle{
            font-weight: 600;
            padding: 10px;
            font-size: 16px;
        }
        .companyName{
            font-size: 14px;
            color: var(--blackColor);
            padding-bottom: 5px;
            span{
                padding-right: 5px;
            }
        }
    }

    .tabSession{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .game-bloc-tabs {
        display: flex;
        width: 70%;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
        margin: 40px 0px 10px;
        .tabs {
          text-align: center;
          background: transparent;;
          box-sizing: content-box;
          position: relative;
          outline: none;
          transition: 0.6s all ease;
          font-family: var(--textFamily);
          font-size: 14px;
          font-weight: normal;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          cursor: pointer;
          background: #99761e15;
          line-height: 40px;
          border-radius: 8px;
          color: var(--blackColor)
          
        }
        .active-tabs  {
          background: transparent;
          color: #202223;
          background: var(--purpleColor);
          line-height: 40px;
          border-radius: 8px;
        }
    }
    .tabContent {
        width: 100%;
        display: none;
        .text{
            font-size: 14px;
            color: var(--blackColor);
            font-family: var(--textFamily);
        }
    }
      
    .active-tabContent {
        display: block;
        margin-top: 10px;
    }
    .tabTitle{
        font-weight: 600;
        font-family: var(--textFamily);
        padding-bottom: 15px;
        font-size: 16px;
    }
    .applyNow{
        display: flex;
        gap: 20px;
        align-items: center;
        margin: 30px 0px;
        .first{
            border: 1px solid var(--purpleColor);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            border-radius: 5px;
            .icon{
                color: var(--purpleColor);
                font-size: 17px;
            }
        }
    }
}