.WebinarRegister{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(36, 37, 39, 0.884);
    /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.12); */
    z-index: 999;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 0px;
    .webinarWrapper{
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        position: relative;
        background: #fff;
        border-radius: 8px;
        padding: 20px 40px;
        width: 60%;
        margin: auto;
        margin-top: 40px;
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 20px;
            button{
                border: 1px solid crimson;
                border-radius: 4px;
                background: var(--lightBackground);
                transition: 0.4s all ease;
                .icon{
                    color: crimson
                }
            }
            button:hover{
                background: var(--primaryColor);
                border: 1px solid var(--primaryColor);
                .icon{
                    color: #fff;
                }
            }
            .pageSubHeading{
                text-transform: uppercase;
                color: var(--blackColor);
                font-weight: 600;
            }
        }
        .datetime{
            color: var(--blackColor);
            span{
                padding-right: 40px;
                font-weight: 600;
                font-style: italic;
            }
        }
        .googleForm{
            padding-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
   
}
@media screen and (max-width: 1100px) {
    .webinarWrapper{
        margin: 10px;
        width: 90%!important;
    }
}
@media screen and (max-width: 900px) {
    .webinarWrapper{
        margin: 10px;
        width: 99%!important;
    }
}
@media screen and (max-width: 600px) {
    .webinarWrapper{
        padding: 20px 10px;
    }
}